import * as React from "react"
import { graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"
import Contact from "../components/contact"
import Text from "../components/text"
import TextImageAndVideo from "../components/text_image_and_video"
import Teams from "../components/teams"
import Heading from "../components/heading"
import CareersForm from "../components/careers_form"

const About = ({data}) => {
  const entry = data.prismicAbout
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.meta_image.url} pathname={linkResolver(entry.data)} />
      <Header logo='cuddon' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'careers_form') {
          return (
            <div key={slice.id}>
              <CareersForm />
            </div>
          )
        }
        if (slice.slice_type === 'contact') {
          return (
            <div key={slice.id}>
              <Contact primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text_image_and_video') {
          return (
            <div key={slice.id}>
              <TextImageAndVideo primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'teams') {
          return (
            <div key={slice.id}>
              <Teams primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicAbout(uid: {eq: $handle}) {
    data {
      meta_description
      page_title
      meta_image {
        url
      }
      body {
        ... on PrismicAboutDataBodyCareersForm {
          id
          slice_type
        }
        ... on PrismicAboutDataBodyText {
          id
          primary {
            button1
            heading1
            page_link1 {
              url
              uid
              type
            }
            text1 {
              richText
            }
            tag
          }
          slice_type
        }
        ... on PrismicAboutDataBodyContact {
          id
          slice_type
          primary {
            heading1
            text1 {
              richText
            }
            background {
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicAboutDataBodyHeading {
          id
          slice_type
          primary {
            breadcrumb
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            parent {
              url
              uid
              type
              link_type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicAboutDataBodyTeams {
          id
          primary {
            tag
          }
          items {
            email
            image1 {
              alt
              gatsbyImageData
            }
            name
            phone
            position
            tel
          }
          slice_type
        }
        ... on PrismicAboutDataBodyTextImageAndVideo {
          id
          slice_type
          primary {
            button_link
            button_pdf
            video
            text1 {
              richText
            }
            pdf {
              url
              uid
              type
              localFile {
                publicURL
              }
              link_type
            }
            page_link1 {
              url
              uid
              type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`

export default About
