import React from 'react';

const CareersForm = () => {
  return (
    <div className="w-full">
      <div className="w-11/12 mx-auto max-w-screen-2xl">
        <iframe src="https://consultusgroup.secure.force.com/Cuddonroles/" width="100%" height="550" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="yes"></iframe>
      </div>
    </div>
  );
}

export default CareersForm;
